import React, { useState, useEffect } from 'react';
import { FiSearch, FiGlobe, FiArrowRight, FiMenu, FiX } from 'react-icons/fi';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import LOGO from '../assets/images/Leo-logo-primary.png';
import ALGLogo from '../assets/images/logo-color.svg';
import HudumaLogo from '../assets/images/huduma-logo.svg';
import ReviewLogo from '../assets/images/LAR-logo.png';
import YelpLogo from '../assets/images/YELP-Logo.svg';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('EN');
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    console.log('Searching for:', searchQuery);
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    setIsLanguageOpen(false);
    console.log('Language selected:', language);
  };

  return (
    <header className={`${isScrolled ? 'bg-gray-100 shadow-lg' : 'bg-white'} sticky top-0 z-50 transition-all duration-300`}>
      {/* Top Row with Social Media Icons */}
      <div className={`max-w-screen-xl mx-auto flex justify-between items-center ${isScrolled ? 'py-1 px-3 text-xs' : 'py-3 px-4 text-sm'}`}>
        <div className="hidden md:flex space-x-4">
          <a href="https://facebook.com" aria-label="Facebook" className="hover:text-[#2bbecb]"><FaFacebookF /></a>
          <a href="https://twitter.com" aria-label="Twitter" className="hover:text-[#2bbecb]"><FaTwitter /></a>
          <a href="https://linkedin.com" aria-label="LinkedIn" className="hover:text-[#2bbecb]"><FaLinkedinIn /></a>
          <a href="https://instagram.com" aria-label="Instagram" className="hover:text-[#2bbecb]"><FaInstagram /></a>
        </div>
        <div className="flex items-center space-x-6">
          <button onClick={toggleSearch} className="focus:outline-none hover:text-[#2bbecb]" aria-label="Search">
            <FiSearch className="w-5 h-5" />
          </button>
          <a href="/get-involved" className="block hover:text-[#2bbecb] underline-hover">Get Involved</a>
          <a href="/partners" className="hover:text-[#2bbecb] underline-hover">Partner with Us</a>
          <a href="/contact" className="hover:text-[#2bbecb] underline-hover">Contact Us</a>
          {/* <a href="/shop" className="hover:text-[#2bbecb] underline-hover">Shop</a> */}

          {/* Language Selection */}
          <div className="relative">
            <button onClick={() => setIsLanguageOpen(!isLanguageOpen)} className="focus:outline-none hover:text-[#2bbecb]" aria-label="Language Selection">
              <FiGlobe className="w-5 h-5" />
            </button>
            {isLanguageOpen && (
              <div className="absolute right-0 mt-2 w-32 bg-white border border-gray-200 rounded shadow-lg z-50">
                <button onClick={() => handleLanguageChange('EN')} className="block px-4 py-2 text-sm hover:bg-gray-100">English</button>
                <button onClick={() => handleLanguageChange('FR')} className="block px-4 py-2 text-sm hover:bg-gray-100">French</button>
                <button onClick={() => handleLanguageChange('ES')} className="block px-4 py-2 text-sm hover:bg-gray-100">Spanish</button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Full-Width Horizontal Line */}
      <div className="w-full h-px bg-gray-300"></div>

      {/* Search Bar */}
      {isSearchOpen && (
        <div className="absolute top-0 left-0 w-full bg-white shadow-md p-4 z-40">
          <form onSubmit={handleSearchSubmit} className="max-w-3xl mx-auto flex items-center space-x-4">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[#2bbecb]"
            />
            <button type="submit" className="bg-[#2bbecb] text-white p-2 rounded hover:bg-[#f6911e] transition-colors">
              Search
            </button>
            <button onClick={toggleSearch} className="text-gray-500 hover:text-gray-700 focus:outline-none">
              <FiX size={24} />
            </button>
          </form>
        </div>
      )}

      {/* Main Navigation */}
      <nav className="max-w-screen-xl mx-auto flex items-center justify-between transition-all duration-300">
        <div className="logo z-50 transition-transform duration-300 transform" style={{ transform: isScrolled ? 'scale(0.8)' : 'scale(1)' }}>
          <a href="/" aria-label="Homepage">
            <img src={LOGO} alt="LéO Africa Institute Logo" className={`${isScrolled ? 'h-12' : 'h-20'} transition-all duration-300 padding-add`} />
          </a>
        </div>

        {/* Desktop Menu */}
        <ul className="hidden md:flex space-x-8 text-lg font-bold">
          <li className="relative group">
            <a href="/about" className="block hover:text-[#f6911e] text-[#3b3b3b] underline-hover group-hover:text-[#f6911e]">About Us</a>
            <div className="absolute top-full left-0 w-screen bg-white shadow-lg border-gray-300 z-40 opacity-0 invisible group-hover:opacity-100 group-hover:visible group-hover:scale-y-100 group-hover:translate-y-0 transform scale-y-75 -translate-y-4 transition-all duration-500 ease-out full-menu">
              <div className="w-full h-px bg-gray-300"></div>
              <div className="max-w-7xl mx-auto grid grid-cols-3 gap-8 p-8 text-gray-700">
                <div>
                  <h3 className="text-xs font-semibold text-[#888888] mb-6 tracking-wider uppercase cursor-default">Our Story</h3>
                  <ul className="space-y-2">
                    <li><a href="/about/our-history" className="hover:text-[#f6911e] text-base font-medium underline-hover">Our History</a></li>
                    <li><a href="/about/leadership" className="hover:text-[#f6911e] text-base font-medium underline-hover">Mission & Vision</a></li>
                    <li><a href="/about/team" className="hover:text-[#f6911e] text-base font-medium underline-hover">The Team</a></li>
                    <li><a href="/about/partners" className="hover:text-[#f6911e] text-base font-medium underline-hover">Our Partners</a></li>
                    <li><a href="/about/awards" className="hover:text-[#f6911e] text-base font-medium underline-hover">Awards & Accolades</a></li>
                    <li><a href="/about/faq" className="hover:text-[#f6911e] text-base font-medium underline-hover">Frequently Asked Questions</a></li>
                  </ul>
                  <a href="/about" className="flex items-center text-sm font-semibold text-[#2bbecb] mt-4 hover:text-[#f6911e] transition-all group">
                    More About Us <FiArrowRight className="ml-2 transform transition-transform duration-300 group-hover:translate-x-2" />
                  </a>
                </div>

                <div>
                  <h3 className="text-xs font-semibold text-[#888888] mb-6 tracking-wider uppercase cursor-default">Connect</h3>
                  <ul className="space-y-2">
                    <li><a href="/contact" className="hover:text-[#f6911e] text-base font-medium underline-hover">Contact Us</a></li>
                    <li><a href="/get-involved" className="hover:text-[#f6911e] text-base font-medium underline-hover">Get Involved</a></li>
                    <li><a href="/partners" className="hover:text-[#f6911e] text-base font-medium underline-hover">Partner with Us</a></li>
                  </ul>
                  <div className="flex space-x-4 mt-4">
                    <a href="https://facebook.com" aria-label="Facebook" className="hover:text-[#2bbecb]"><FaFacebookF /></a>
                    <a href="https://twitter.com" aria-label="Twitter" className="hover:text-[#2bbecb]"><FaTwitter /></a>
                    <a href="https://linkedin.com" aria-label="LinkedIn" className="hover:text-[#2bbecb]"><FaLinkedinIn /></a>
                    <a href="https://instagram.com" aria-label="Instagram" className="hover:text-[#2bbecb]"><FaInstagram /></a>
                  </div>
                </div>

                <div>
                  <h3 className="text-xs font-semibold text-[#888888] mb-6 tracking-wider uppercase cursor-default">From the Newsroom</h3>
                  <ul className="space-y-2">
                    <li>
                      <a href="/news" className="hover:text-[#f6911e] text-base font-bold underline-hover block leading-tight">
                        Opportunities for Public Private Partnerships (PPP) in Kampala City Road Repairs
                      </a>
                      <p className="text-[#888] text-sm padding-add">To improve the resilience of global infrastructure, we need new approaches to public-private partnerships, project finance, and risk management.</p>
                    </li>
                    <li className="mt-6">
                      <a href="/news" className="hover:text-[#f6911e] text-base font-bold underline-hover block leading-tight">
                        Leadership Lessons from a Pandemic
                      </a>
                      <p className="text-[#888] text-sm padding-add">Humanity, however, is always ready to deal with short-term, clear dangers like coronavirus but less so with multi-decade issues like climate change.</p>
                    </li>
                  </ul>
                  <a href="/news" className="flex items-center text-sm font-semibold text-[#2bbecb] mt-4 hover:text-[#f6911e] transition-all group">
                    View All News <FiArrowRight className="ml-2 transform transition-transform duration-300 group-hover:translate-x-2" />
                  </a>
                </div>
              </div>
            </div>
          </li>

          {/* Other Menu Items */}

          <li className="relative group">
            <a href="/about" className="block hover:text-[#f6911e] text-[#3b3b3b] underline-hover group-hover:text-[#f6911e]">Events & Gatherings</a>
            <div className="absolute top-full left-0 w-screen bg-white shadow-lg border-gray-300 z-40 opacity-0 invisible group-hover:opacity-100 group-hover:visible group-hover:scale-y-100 group-hover:translate-y-0 transform scale-y-75 -translate-y-4 transition-all duration-500 ease-out full-menu events">
              <div className="w-full h-px bg-gray-300"></div>
              <div className="max-w-7xl mx-auto grid grid-cols-3 gap-8 p-8 text-gray-700">
                <div>
                  <h3 className="text-xs font-semibold text-[#888888] mb-6 tracking-wider uppercase cursor-default">Our Next Major Event</h3>
                  <ul className="space-y-2">
                  <li><a href="/events/alg" className="hover:text-[#f6911e] text-base font-medium underline-hover">Annual Leaders Gathering</a></li>
          <li><a href="/events/yelp" className="hover:text-[#f6911e] text-base font-medium underline-hover">YELP Fellowship</a></li>
          <li><a href="/events/huduma" className="hover:text-[#f6911e] text-base font-medium underline-hover">Huduma Fellowship</a></li>
          <li><a href="/events/leo-talks" className="hover:text-[#f6911e] text-base font-medium underline-hover">LéO Talks</a></li>
          <li><a href="/events/leo-debates" className="hover:text-[#f6911e] text-base font-medium underline-hover">LéO Debates</a></li>
                  </ul>
                  <a href="/events" className="flex items-center text-sm font-semibold text-[#2bbecb] mt-4 hover:text-[#f6911e] transition-all group">
          View All Events <FiArrowRight className="ml-2 transform transition-transform duration-300 group-hover:translate-x-2" />
        </a>
                </div>

                <div>
                  <h3 className="text-xs font-semibold text-[#888888] mb-6 tracking-wider uppercase cursor-default">Upcoming Events</h3>
                  <ul className="space-y-2">
                    <li><a href="/events/leadership" className="hover:text-[#f6911e] text-base font-medium underline-hover">Leadership Development</a></li>
          <li><a href="/events/policy" className="hover:text-[#f6911e] text-base font-medium underline-hover">Policy & Governance</a></li>
          <li><a href="/events/innovation" className="hover:text-[#f6911e] text-base font-medium underline-hover">Innovation & Technology</a></li>
          <li><a href="/events/culture" className="hover:text-[#f6911e] text-base font-medium underline-hover">Arts & Culture</a></li>
          <li><a href="/events/business" className="hover:text-[#f6911e] text-base font-medium underline-hover">Business & Entrepreneurship</a></li>
                  </ul>
                  <div className="flex space-x-4 mt-4">
                    <a href="https://facebook.com" aria-label="Facebook" className="hover:text-[#2bbecb]"><FaFacebookF /></a>
                    <a href="https://twitter.com" aria-label="Twitter" className="hover:text-[#2bbecb]"><FaTwitter /></a>
                    <a href="https://linkedin.com" aria-label="LinkedIn" className="hover:text-[#2bbecb]"><FaLinkedinIn /></a>
                    <a href="https://instagram.com" aria-label="Instagram" className="hover:text-[#2bbecb]"><FaInstagram /></a>
                  </div>
                </div>

                <div>
                  <h3 className="text-xs font-semibold text-[#888888] mb-6 tracking-wider uppercase cursor-default">Browse our Past Events</h3>
                  <ul className="space-y-2">
                    <li>
                      <a href="/news" className="hover:text-[#f6911e] text-base font-bold underline-hover block leading-tight">
                        Opportunities for Public Private Partnerships (PPP) in Kampala City Road Repairs
                      </a>
                      <p className="text-[#888] text-sm padding-add">To improve the resilience of global infrastructure, we need new approaches to public-private partnerships, project finance, and risk management.</p>
                    </li>
                    <li className="mt-6">
                      <a href="/news" className="hover:text-[#f6911e] text-base font-bold underline-hover block leading-tight">
                        Leadership Lessons from a Pandemic
                      </a>
                      <p className="text-[#888] text-sm padding-add">Humanity, however, is always ready to deal with short-term, clear dangers like coronavirus but less so with multi-decade issues like climate change.</p>
                    </li>
                  </ul>
                  <a href="/news" className="flex items-center text-sm font-semibold text-[#2bbecb] mt-4 hover:text-[#f6911e] transition-all group">
                    View All News <FiArrowRight className="ml-2 transform transition-transform duration-300 group-hover:translate-x-2" />
                  </a>
                </div>
              </div>
            </div>
          </li>


          <li className="relative group">
            <a href="/about" className="block hover:text-[#f6911e] text-[#3b3b3b] underline-hover group-hover:text-[#f6911e]">Fellows & Champions</a>
            <div className="absolute top-full left-0 w-screen bg-white shadow-lg border-gray-300 z-40 opacity-0 invisible group-hover:opacity-100 group-hover:visible group-hover:scale-y-100 group-hover:translate-y-0 transform scale-y-75 -translate-y-4 transition-all duration-500 ease-out full-menu fellows">
              <div className="w-full h-px bg-gray-300"></div>
              <div className="max-w-7xl mx-auto grid grid-cols-3 gap-8 p-8 text-gray-700">
                <div>
                  <h3 className="text-xs font-semibold text-[#888888] mb-6 tracking-wider uppercase cursor-default">Our Next Major Event</h3>
                  <ul className="space-y-2">
                    <li><a href="/about/our-history" className="hover:text-[#f6911e] text-base font-medium underline-hover">Our History</a></li>
                    <li><a href="/about/leadership" className="hover:text-[#f6911e] text-base font-medium underline-hover">Mission & Vision</a></li>
                    <li><a href="/about/team" className="hover:text-[#f6911e] text-base font-medium underline-hover">The Team</a></li>
                    <li><a href="/about/partners" className="hover:text-[#f6911e] text-base font-medium underline-hover">Our Partners</a></li>
                    <li><a href="/about/awards" className="hover:text-[#f6911e] text-base font-medium underline-hover">Awards & Accolades</a></li>
                    <li><a href="/about/faq" className="hover:text-[#f6911e] text-base font-medium underline-hover">Frequently Asked Questions</a></li>
                  </ul>
                  <a href="/about" className="flex items-center text-sm font-semibold text-[#2bbecb] mt-4 hover:text-[#f6911e] transition-all group">
                    More About Us <FiArrowRight className="ml-2 transform transition-transform duration-300 group-hover:translate-x-2" />
                  </a>
                </div>

                <div>
                  <h3 className="text-xs font-semibold text-[#888888] mb-6 tracking-wider uppercase cursor-default">Upcoming Events</h3>
                  <ul className="space-y-2">
                    <li><a href="/contact" className="hover:text-[#f6911e] text-base font-medium underline-hover">Contact Us</a></li>
                    <li><a href="/get-involved" className="hover:text-[#f6911e] text-base font-medium underline-hover">Get Involved</a></li>
                    <li><a href="/partners" className="hover:text-[#f6911e] text-base font-medium underline-hover">Partner with Us</a></li>
                  </ul>
                  <div className="flex space-x-4 mt-4">
                    <a href="https://facebook.com" aria-label="Facebook" className="hover:text-[#2bbecb]"><FaFacebookF /></a>
                    <a href="https://twitter.com" aria-label="Twitter" className="hover:text-[#2bbecb]"><FaTwitter /></a>
                    <a href="https://linkedin.com" aria-label="LinkedIn" className="hover:text-[#2bbecb]"><FaLinkedinIn /></a>
                    <a href="https://instagram.com" aria-label="Instagram" className="hover:text-[#2bbecb]"><FaInstagram /></a>
                  </div>
                </div>

                <div>
                  <h3 className="text-xs font-semibold text-[#888888] mb-6 tracking-wider uppercase cursor-default">Browse our Past Events</h3>
                  <ul className="space-y-2">
                    <li>
                      <a href="/news" className="hover:text-[#f6911e] text-base font-bold underline-hover block leading-tight">
                        Opportunities for Public Private Partnerships (PPP) in Kampala City Road Repairs
                      </a>
                      <p className="text-[#888] text-sm padding-add">To improve the resilience of global infrastructure, we need new approaches to public-private partnerships, project finance, and risk management.</p>
                    </li>
                    <li className="mt-6">
                      <a href="/news" className="hover:text-[#f6911e] text-base font-bold underline-hover block leading-tight">
                        Leadership Lessons from a Pandemic
                      </a>
                      <p className="text-[#888] text-sm padding-add">Humanity, however, is always ready to deal with short-term, clear dangers like coronavirus but less so with multi-decade issues like climate change.</p>
                    </li>
                  </ul>
                  <a href="/news" className="flex items-center text-sm font-semibold text-[#2bbecb] mt-4 hover:text-[#f6911e] transition-all group">
                    View All News <FiArrowRight className="ml-2 transform transition-transform duration-300 group-hover:translate-x-2" />
                  </a>
                </div>
              </div>
            </div>
          </li>



          <li className="relative group">
            <a href="/about" className="block hover:text-[#f6911e] text-[#3b3b3b] underline-hover group-hover:text-[#f6911e]">News & Media</a>
            <div className="absolute top-full left-0 w-screen bg-white shadow-lg border-gray-300 z-40 opacity-0 invisible group-hover:opacity-100 group-hover:visible group-hover:scale-y-100 group-hover:translate-y-0 transform scale-y-75 -translate-y-4 transition-all duration-500 ease-out full-menu news">
              <div className="w-full h-px bg-gray-300"></div>
              <div className="max-w-7xl mx-auto grid grid-cols-3 gap-8 p-8 text-gray-700">
                <div>
                  <h3 className="text-xs font-semibold text-[#888888] mb-6 tracking-wider uppercase cursor-default">BY TYPE</h3>
                  <ul className="space-y-2">
                    <li><a href="/about/our-history" className="hover:text-[#f6911e] text-base font-medium underline-hover">Articles & Opinions</a></li>
                    <li><a href="/about/leadership" className="hover:text-[#f6911e] text-base font-medium underline-hover">White Papers</a></li>
                    <li><a href="/about/team" className="hover:text-[#f6911e] text-base font-medium underline-hover">Research & Reports</a></li>
                    <li><a href="/about/partners" className="hover:text-[#f6911e] text-base font-medium underline-hover">Webinars & Events</a></li>
                    <li><a href="/about/awards" className="hover:text-[#f6911e] text-base font-medium underline-hover">Audio & Video</a></li>
                  </ul>
                  <a href="/about" className="flex items-center text-sm font-semibold text-[#2bbecb] mt-4 hover:text-[#f6911e] transition-all group">
                    Browse All<FiArrowRight className="ml-2 transform transition-transform duration-300 group-hover:translate-x-2" />
                  </a>
                </div>

                <div>
                  <h3 className="text-xs font-semibold text-[#888888] mb-6 tracking-wider uppercase cursor-default">BY CATEGORY</h3>
                  <ul className="space-y-2">
                    <li><a href="/contact" className="hover:text-[#f6911e] text-base font-medium underline-hover">Innovation & Creativity</a></li>
                    <li><a href="/get-involved" className="hover:text-[#f6911e] text-base font-medium underline-hover">Leadership Culture</a></li>
                    <li><a href="/partners" className="hover:text-[#f6911e] text-base font-medium underline-hover">Health & Wellbeing</a></li>
                    <li><a href="/partners" className="hover:text-[#f6911e] text-base font-medium underline-hover">Women in Leadership</a></li>
                    <li><a href="/partners" className="hover:text-[#f6911e] text-base font-medium underline-hover">Resilience & Stress</a></li>
                    <li><a href="/partners" className="hover:text-[#f6911e] text-base font-medium underline-hover">Work Life Balance</a></li>
                  </ul>
                  <a href="#" className="flex items-center text-sm font-semibold text-[#2bbecb] mt-4 hover:text-[#f6911e] transition-all group">
                    Browse All Categories<FiArrowRight className="ml-2 transform transition-transform duration-300 group-hover:translate-x-2" />
                  </a>
                </div>

                <div>
                  <h3 className="text-xs font-semibold text-[#888888] mb-6 tracking-wider uppercase cursor-default">LATEST INSIGHTS</h3>
                  <ul className="space-y-2">
                    <li>
                      <a href="/news" className="hover:text-[#f6911e] text-base font-bold underline-hover block leading-tight">
                        Opportunities for Public Private Partnerships (PPP) in Kampala City Road Repairs
                      </a>
                      <p className="text-[#888] text-sm padding-add">To improve the resilience of global infrastructure, we need new approaches to public-private partnerships, project finance, and risk management.</p>
                    </li>
                    <li className="mt-6">
                      <a href="/news" className="hover:text-[#f6911e] text-base font-bold underline-hover block leading-tight">
                        Leadership Lessons from a Pandemic
                      </a>
                      <p className="text-[#888] text-sm padding-add">Humanity, however, is always ready to deal with short-term, clear dangers like coronavirus but less so with multi-decade issues like climate change.</p>
                    </li>
                  </ul>
                  <a href="/news" className="flex items-center text-sm font-semibold text-[#2bbecb] mt-4 hover:text-[#f6911e] transition-all group">
                    View All News <FiArrowRight className="ml-2 transform transition-transform duration-300 group-hover:translate-x-2" />
                  </a>
                </div>
              </div>
            </div>
          </li>



          


          <li className="relative group">
            <a href="/initiatives" className="block hover:text-[#2bbecb] text-[#3b3b3b] font-bold underline-hover">Initiatives</a>
            <div className="absolute top-full left-0 w-screen bg-white shadow-lg border-gray-300 z-40 opacity-0 invisible group-hover:opacity-100 group-hover:visible group-hover:scale-y-100 group-hover:translate-y-0 transform scale-y-75 -translate-y-4 transition-all duration-500 ease-out full-menu initiatives">
              <div className="w-full h-px bg-gray-300"></div>
              <div className="max-w-7xl mx-auto grid grid-cols-4 gap-8 p-8 text-gray-700">
                <h3 className="col-span-4 text-center text-xs font-semibold text-[#888888] mb-6 tracking-wider uppercase">What We Are Doing</h3>
                <div className="flex flex-col items-center text-center space-y-2">
                  <a href="https://alg.leoafricainstitute.org/" target="_blank" rel="noopener noreferrer" className="block hover:text-[#2bbecb]">
                    <img src={ALGLogo} alt="ALG Logo" className="h-12 w-auto mb-2" />
                  </a>
                  <h3 className="text-base font-bold">Annual Leaders Gathering</h3>
                  <p className="text-sm text-gray-600">A convergence of leaders driving transformative change across Africa.</p>
                </div>
                <div className="flex flex-col items-center text-center space-y-2">
                  <a href="https://huduma.leoafricainstitute.org/" target="_blank" rel="noopener noreferrer" className="block hover:text-[#2bbecb]">
                    <img src={HudumaLogo} alt="Huduma Fellowship Logo" className="h-12 w-auto mb-2" />
                  </a>
                  <h3 className="text-base font-bold">Huduma Fellowship</h3>
                  <p className="text-sm text-gray-600">Empowering young leaders in public service for impactful leadership.</p>
                </div>
                <div className="flex flex-col items-center text-center space-y-2">
                  <a href="https://leoafricareview.com/" target="_blank" rel="noopener noreferrer" className="block hover:text-[#2bbecb]">
                    <img src={ReviewLogo} alt="LeO Africa Review Logo" className="h-12 w-auto mb-2" />
                  </a>
                  <h3 className="text-base font-bold">LeO Africa Review</h3>
                  <p className="text-sm text-gray-600">Insights and reflections on Africa's journey and growth.</p>
                </div>
                <div className="flex flex-col items-center text-center space-y-2">
                  <a href="https://yelp.leoafricainstitute.org/" target="_blank" rel="noopener noreferrer" className="block hover:text-[#2bbecb]">
                    <img src={YelpLogo} alt="Yelp Logo" className="h-12 w-auto mb-2" />
                  </a>
                  <h3 className="text-base font-bold">Young and Emerging Leaders Program (YELP)</h3>
                  <p className="text-sm text-gray-600">Fostering a network of visionary young leaders across Africa.</p>
                </div>
              </div>
            </div>
          </li>
        </ul>

        {/* Hamburger Icon for Mobile View */}
        <button onClick={toggleMenu} className="md:hidden focus:outline-none" aria-label="Menu">
          {isMenuOpen ? <FiX className="w-6 h-6" /> : <FiMenu className="w-6 h-6" />}
        </button>
      </nav>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="md:hidden bg-white shadow-lg z-40">
          <ul className="flex flex-col space-y-4 p-4 text-lg font-semibold">
            <li><a href="/about" className="block hover:text-[#2bbecb]">About Us</a></li>
            <li><a href="/fellows" className="block hover:text-[#2bbecb]">Fellows & Champions</a></li>
            <li><a href="/events" className="block hover:text-[#2bbecb]">Events & Gatherings</a></li>
            <li><a href="/publications" className="block hover:text-[#2bbecb]">News & Media</a></li>
            <li><a href="/initiatives" className="block hover:text-[#2bbecb]">Initiatives</a></li>
            <li><a href="/contact" className="block hover:text-[#2bbecb]">Contact Us</a></li>
          </ul>
        </div>
      )}
    </header>
  );
};

export default Navbar;
